<template>
    <div class="chart-page--container flex-item">
        <div :id="key" class="chart-page"></div>

        <canvas-data v-if="canvasData.show" :param="canvasData"></canvas-data>
    </div>
</template>


<script>
    import graphMixin from '@/util/graphMixin'
    export default {
        props: ['corp', 'visitId'],
        mixins: [graphMixin],
        data() {
            return {
                key: 'assistTreat',
                nodes: [],
                lines: [],

                canvasData: {
                    show: false,
                    title: '',
                    api: '',
                    field: '',
                    baseQuery: {},
                },

                firstKey: 'treatBig',

                secondKey: 'treatSmall',
                secondQtyKey: '',
                secondApi: 'indexMedicalAssistant/baseDrugs/treatBigComNamePage',

                thirdKey: 'comName',
                thirdQtyKey: 'saleAmount',
                thirdApi: 'indexMedicalAssistant/baseDrugs/treatBigComNamePage',

            }
        },
        created() {
            this.$set(this.corp, this.firstKey, this.corp.treatBig ? this.corp.treatBig : '内科用药（中）')
        },
    }
</script>