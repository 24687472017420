<template>
    <div class="common-table search-result">
        <div class="common-table__title" style="display:flex;">
            <div class="common-table__title-list" v-if="corpList.length > 0">
                <el-dropdown size="normal" @command="checkCorp">
                    <span class="el-dropdown-link">
                        {{ corp.comName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu class="search-popup" slot="dropdown">
                        <el-dropdown-item v-for="item in corpList" :key="item.id" :command="item"
                            :disabled="item.id === corp.id">{{ item.comName }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="common-table__title-list" v-else>{{ isEmpty ? '快速检索' : corp.comName }}</div>
            <ts-search :param="express"></ts-search>
        </div>

        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="instruction"><span slot="label">药品说明书</span></el-tab-pane>
                <!--<el-tab-pane name="point"><span slot="label">临床用药指南</span></el-tab-pane>-->
                <!--<el-tab-pane name="path"><span slot="label">临床路径</span></el-tab-pane>-->
                <el-tab-pane name="doc"><span slot="label">产品文献</span></el-tab-pane>
                <el-tab-pane name="analysis"><span slot="label">竞品分析</span></el-tab-pane>
                <el-tab-pane name="product"><span slot="label">产品分析</span></el-tab-pane>
                <!--<el-tab-pane name="dose"><span slot="label">通用名-剂型</span></el-tab-pane>-->
                <el-tab-pane name="component"><span slot="label">通用名-成分</span></el-tab-pane>
                <el-tab-pane name="indication"><span slot="label">通用名-适应症</span></el-tab-pane>
                <el-tab-pane name="treat"><span slot="label">通用名-治疗类别</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{ show: tab === 'instruction' }">
                <assist-instruction-info v-if="finished.instruction" :base-query="baseQuery"
                    :visit-id="visitId"></assist-instruction-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'point' }">
                <assist-point-info v-if="finished.point" :base-query="baseQuery"
                    :visit-id="visitId"></assist-point-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'path' }">
                <assist-path-info v-if="finished.path" :base-query="baseQuery" :visit-id="visitId"></assist-path-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'doc' }">
                <assist-doc-info v-if="finished.doc" :base-query="products" :visit-id="visitId"></assist-doc-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'analysis' }">
                <assist-competitor-info v-if="finished.analysis" :base-query="products"
                    :visit-id="visitId"></assist-competitor-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'product' }">
                <assist-product-info v-if="finished.product" :base-query="products"
                    :visit-id="visitId"></assist-product-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'dose' }">
                <assist-dose-info v-if="finished.dose" :corp="corp" :visit-id="visitId"></assist-dose-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'component' }">
                <assist-component-info v-if="finished.component" :corp="corp"
                    :visit-id="visitId"></assist-component-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'indication' }">
                <assist-indication-info v-if="finished.indication" :corp="corp"
                    :visit-id="visitId"></assist-indication-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'treat' }">
                <assist-treat-info v-if="finished.treat" :corp="corp" :visit-id="visitId"></assist-treat-info>
            </div>
        </div>
    </div>
</template>

<script>
import AssistCompetitorInfo from './AssistCompetitorInfo'
import AssistDocInfo from './AssistDocInfo'
import AssistInstructionInfo from './AssistInstructionInfo'
import AssistPathInfo from './AssistPathInfo'
import AssistPointInfo from './AssistPointInfo'
import AssistProductInfo from './AssistProductInfo'
import AssistDoseInfo from './AssistDoseInfo'
import AssistComponentInfo from './AssistComponentInfo'
import AssistIndicationInfo from './AssistIndicationInfo'
import AssistTreatInfo from './AssistTreatInfo'
export default {
    components: {
        AssistCompetitorInfo, AssistDocInfo, AssistInstructionInfo, AssistPathInfo, AssistDoseInfo,
        AssistPointInfo, AssistProductInfo, AssistComponentInfo, AssistIndicationInfo, AssistTreatInfo
    },
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',
            pageMap: {
                instruction: '药品说明书',
                point: '临床用药指南',
                path: '临床路径',
                doc: '产品文献',
                analysis: '竞品分析',
                product: '产品分析',
                dose: '通用名-剂型',
                component: '通用名-成分',
                indication: '通用名-适应症',
                treat: '通用名-治疗类别',
            },

            tab: 'instruction',
            loaded: false,
            express: {
                txt: '',
                type: '',
            },
            baseQuery: {},
            products: {},

            finished: {
                instruction: false,
                point: false,
                path: false,
                doc: false,
                analysis: false,
                product: false,
                dose: false,
                component: false,
                indication: false,
                treat: false,
            },

            corpList: [],
            corp: {},
            isEmpty: false,
        }
    },
    created() {
        this.init();
    },
    watch: {
        '$route': function () {
            this.init();
        },
        tab: {
            handler(val) {
                this.finished[val] = true

                this.visitId = new Date().getTime()
                this.pageId = val
                this.pageName = '用药助手搜索-' + this.pageMap[val]

                this.$help.socket.send(this)
            },
            immediate: true
        },
    },
    methods: {
        init() {
            const txt = this.$route.params.txt
            this.corpList = []
            this.corp = {}
            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', { keyword: txt })
                .then(res => {
                    if (res.data && res.data.length > 1) {
                        this.corpList = res.data
                        this.checkCorp(res.data[0])
                    } else if (res.data.length === 0) {
                        this.isEmpty = true
                    } else {
                        this.checkCorp(res.data[0])
                    }

                })

            this.express.txt = txt
            this.express.type = this.$variable.indexType.ASSIST
        },
        checkCorp(item) {
            if (item.comCode !== this.corp.comCode) {
                this.corp = item
                this.tab = 'instruction'
                this.loaded = false
                this.baseQuery = {
                    comName: item.comName,
                    //keyword: item.comName,
                }
                this.products = {
                    products: `%${item.comName}%`,
                }
                this.$nextTick(() => {
                    this.loaded = true
                })
            }
        },
    },
}
</script>